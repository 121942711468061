import { Menu } from "antd";
import classNames from "classnames";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { useMemo, useState } from "react";

import { isInPortal } from "utilities/portal/portal";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTIconCaretSmallDown, BTIconCaretSmallUp } from "commonComponents/btWrappers/BTIcon";
import { BTIconPlus } from "commonComponents/btWrappers/BTIcon";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import MainNavTooltip from "commonComponents/utilities/MainNavigation/common/MainNavTooltip";
import { QuickAddLink } from "commonComponents/utilities/MainNavigation/common/QuickAddLink";
import {
    MainNavigationResponse,
    NavMenuItemResponse,
} from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";
import {
    IMenuItemClickRequest,
    IMenuTabInfo,
    MenuInfo,
    menuItemClass,
} from "commonComponents/utilities/MainNavigation/MainNavigation.types";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";

interface IBTQuickAddMenuItemProps {
    itemKey: string;
    parentKey: keyof MenuInfo;
    displayName: string;
    menuInfo: MenuInfo;
    data: MainNavigationResponse;
    routeInfo: IMenuItemClickRequest;
    showOnTemplate?: boolean;
    showNewIndicator?: boolean;
}

interface IBTQuickAddMenuItem {
    menuItem: React.ReactNode;
    hasQuickAddPermission: boolean;
    isQuickAddPreference: boolean;
    quickAddCount: number;
    showOnTemplate?: boolean;
}

// Can not be a functional component, antd needs Menu.SubMenu
// and Menu.Item to be direct children of Menu
const BTQuickAddMenuOption: (props: IBTQuickAddMenuItemProps) => IBTQuickAddMenuItem = ({
    itemKey,
    parentKey,
    displayName,
    menuInfo,
    data,
    routeInfo,
    showOnTemplate,
    showNewIndicator,
}) => {
    const response: NavMenuItemResponse = data[parentKey][itemKey];
    const dropdownItemRecord: IMenuTabInfo = menuInfo[parentKey][itemKey]!;
    const menuItemContent = (
        <div className="MainNavMenuItemContent">
            {dropdownItemRecord.icon}
            <div style={{ height: "100%" }}>{displayName}</div>
        </div>
    );
    if (!response || response.requiredPackage || !isInPortal({ builder: true })) {
        return {
            menuItem: null,
            hasQuickAddPermission: false,
            isQuickAddPreference: false,
            quickAddCount: 0,
            showOnTemplate: showOnTemplate,
        };
    }
    const quickAddActions =
        dropdownItemRecord.quickAddInfo === undefined || !response.canQuickAdd
            ? []
            : dropdownItemRecord.quickAddInfo.filter((quickAddInfo) => {
                  const onclickDefined = quickAddInfo.onClick !== undefined;
                  let canQuickAddCallback = true;

                  if (quickAddInfo.canQuickAdd !== undefined) {
                      canQuickAddCallback = quickAddInfo.canQuickAdd(data);
                  }

                  return onclickDefined || canQuickAddCallback;
              });
    if (quickAddActions.length === 0) {
        return {
            menuItem: null,
            hasQuickAddPermission: false,
            isQuickAddPreference: false,
            quickAddCount: 0,
            showOnTemplate: showOnTemplate,
        };
    }

    if (!response.isQuickAddPreference) {
        return {
            menuItem: (
                <BTMenuItem
                    data-testid={`QuickAddMenu - ${itemKey}`}
                    key={`QuickAddMenu - ${itemKey}`}
                >
                    <div className="MainNavMenuItemContainer">
                        <div className={menuItemClass}>
                            <QuickAddLink
                                key={`QuickActions-${itemKey}`}
                                isFlyout={false}
                                routeInfo={routeInfo}
                                info={quickAddActions[0]}
                                className="MainNavMenuItemAction"
                            >
                                {menuItemContent}
                            </QuickAddLink>
                            {showNewIndicator && (
                                <StatusTagDisplay statusType="success" statusText="NEW" />
                            )}
                        </div>
                    </div>
                </BTMenuItem>
            ),
            hasQuickAddPermission: true,
            isQuickAddPreference: false,
            quickAddCount: 0,
            showOnTemplate: showOnTemplate,
        };
    }

    return {
        menuItem: (
            <BTMenuItem data-testid={`QuickAddMenu - ${itemKey}`} key={`QuickAddMenu - ${itemKey}`}>
                <div className="MainNavMenuItemContainer">
                    <div className={menuItemClass}>
                        <QuickAddLink
                            key={`QuickActions-${itemKey}`}
                            isFlyout={false}
                            routeInfo={routeInfo}
                            info={quickAddActions[0]}
                            className="MainNavMenuItemAction"
                        >
                            {menuItemContent}
                        </QuickAddLink>
                    </div>
                </div>
            </BTMenuItem>
        ),
        hasQuickAddPermission: true,
        isQuickAddPreference: true,
        quickAddCount: response.quickAddCounts,
        showOnTemplate: showOnTemplate,
    };
};

const GetQuickAddOptions = (
    commonProps: Omit<IBTQuickAddMenuItemProps, "parentKey" | "itemKey" | "displayName">
) => [
    BTQuickAddMenuOption({
        parentKey: "sales",
        itemKey: "leadOpportunities",
        displayName: "Lead Opportunity",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "sales",
        itemKey: "leadActivities",
        displayName: "Lead Activity",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "sales",
        itemKey: "leadProposals",
        displayName: "Lead Proposal",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "projectManagement",
        itemKey: "schedule",
        displayName: "Schedule Item",
        showOnTemplate: true,
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "projectManagement",
        itemKey: "dailyLogs",
        displayName: "Daily Log",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "projectManagement",
        itemKey: "todos",
        displayName: "To-Do",
        showOnTemplate: true,
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "projectManagement",
        itemKey: "changeOrders",
        displayName: "Change Order",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "projectManagement",
        itemKey: "selections",
        displayName: "Selection",
        showOnTemplate: true,
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "projectManagement",
        itemKey: "warranty",
        displayName: "Warranty Claim",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "projectManagement",
        itemKey: "timeClock",
        displayName: "Time Clock Shift",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "projectManagement",
        itemKey: "specifications",
        displayName: "Specifications",
        showOnTemplate: true,
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "messaging",
        itemKey: "messages",
        displayName: "Message",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "messaging",
        itemKey: "rfis",
        displayName: "RFI",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "financial",
        itemKey: "bids",
        displayName: "Bid Package",
        showOnTemplate: true,
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "financial",
        itemKey: "purchaseOrders",
        displayName: "Purchase Order",
        showOnTemplate: true,
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "financial",
        itemKey: "bills",
        displayName: "Bill",
        showOnTemplate: true,
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "financial",
        itemKey: "receipts",
        displayName: "Receipt",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "financial",
        itemKey: "ownerInvoices",
        displayName: "Invoice",
        showOnTemplate: true,
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "users",
        itemKey: "internalUsers",
        displayName: "Internal User",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "users",
        itemKey: "subs",
        displayName: "Sub/Vendor",
        ...commonProps,
    }),
    BTQuickAddMenuOption({
        parentKey: "users",
        itemKey: "contacts",
        displayName: "Customer Contact",
        ...commonProps,
    }),
];

export interface IExperimentQuickAdd {
    menuInfo: MenuInfo;
    data: MainNavigationResponse;
    routeInfo: IMenuItemClickRequest;
    isTemplateMode: boolean;
    useNewText?: boolean;
}

export const ExperimentPersonalizedQuickAdd: React.FunctionComponent<IExperimentQuickAdd> = (
    props
) => {
    const [showAll, setShowAll] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    let quickAddOptions = useMemo(() => GetQuickAddOptions(props), [props]);
    if (props.isTemplateMode) {
        quickAddOptions = quickAddOptions.filter((x) => x.showOnTemplate);
    }
    const hasPermissionsForAnyQuickAdd = quickAddOptions.some((item) => item.hasQuickAddPermission);

    const handleVisibleChange = (visible: boolean) => {
        setDropdownVisible(visible);
        setShowAll(false);
    };

    const handleMenuClick: MenuClickEventHandler = (e) => {
        if (e.key !== "ShowMoreLink") {
            setDropdownVisible(false);
        }
    };

    const personalizedItems = quickAddOptions
        .filter((item) => item.hasQuickAddPermission && item.isQuickAddPreference)
        .sort((a, b) => b.quickAddCount - a.quickAddCount);
    const remainingItems = quickAddOptions.filter(
        (item) => item.hasQuickAddPermission && !item.isQuickAddPreference
    );
    const showExpand = personalizedItems.length + remainingItems.length > 5;
    // if all items have 0 counts they are the role defaults
    const areRoleDefaults = personalizedItems.every((item) => item.quickAddCount === 0);

    const collapseItems = personalizedItems.slice(0, 5);
    let collapseMenu = collapseItems.map((item) => item.menuItem);

    if (collapseItems.length < 5) {
        const itemsRemaining = 5 - collapseItems.length;
        const itemsToAdd = remainingItems.slice(0, itemsRemaining).map((item) => item.menuItem);
        collapseMenu.push(<Menu.Divider key="divider" />);
        collapseMenu.push(itemsToAdd);
    }

    const overlay = useMemo(
        () => (
            <BTMenu onClick={handleMenuClick} className="PersonalizedScroll PersonalizedItemGroup">
                {!areRoleDefaults && (
                    <BTMenuItem data-testid="PersonalizedHeader" key="PersonalizedHeader">
                        <div className={classNames("QuickAddHeaderContainer")}>
                            <div className="QuickAddHeader">Frequently Added</div>
                        </div>
                    </BTMenuItem>
                )}
                {!showAll ? (
                    <>{collapseMenu}</>
                ) : (
                    <>
                        {personalizedItems.map((item) => item.menuItem)}
                        {remainingItems.length > 0 && (
                            <>
                                {!areRoleDefaults && <Menu.Divider />}
                                {remainingItems.map((item) => item.menuItem)}
                            </>
                        )}
                    </>
                )}

                {showExpand && (
                    <Menu.ItemGroup key="PersonalizedSticky" className="PersonalizedSticky">
                        <div className="PersonalizedExpand">
                            <BTButton
                                data-testid="quickAddShowMore"
                                type="link"
                                onClick={() => setShowAll(!showAll)}
                            >
                                {showAll ? <BTIconCaretSmallUp /> : <BTIconCaretSmallDown />}
                            </BTButton>
                        </div>
                    </Menu.ItemGroup>
                )}
            </BTMenu>
        ),
        [showAll, areRoleDefaults, showExpand, collapseMenu, personalizedItems, remainingItems]
    );

    if (!hasPermissionsForAnyQuickAdd) {
        return null;
    } else {
        return (
            <BTDropdown
                overlayClassName="MainNavDropdownMenu"
                getPopupContainer={(trigger) => trigger.parentElement!}
                overlay={overlay}
                visible={dropdownVisible}
                onVisibleChange={handleVisibleChange}
            >
                <MainNavTooltip
                    title="Create new item"
                    className="MainNavDropdown"
                    enabled={!dropdownVisible}
                >
                    <BTButton
                        data-testid="exp-personalized-main-nav-button"
                        aria-label={props["aria-label"]}
                        noShadow
                        className="MainNavDropdown-icon-only margin-left-xs"
                        onClick={() => setDropdownVisible((prev) => !prev)}
                    >
                        <BTIconPlus />
                    </BTButton>
                </MainNavTooltip>
            </BTDropdown>
        );
    }
};
